import React from 'react';
import styles from './modal.module.css';
import cx from 'classnames';

export const Modal = ({
    show = false,
    handleClose = () => {},
    className = '',
    hasBack = true,
    children,
}) => {
    return show ? (
        <div className={cx(styles.modal, className)}>
            <ModalBack {...{ handleClose, show: hasBack }} />
            {children}
        </div>
    ) : (
        <></>
    );
};

export const ModalBack = ({ handleClose = () => {}, show = true }) => {
    return show ? <div className={styles.modalBack} onClick={handleClose}></div> : <></>;
};

export const ModalHeader = ({ title = '' }) => {
    return <div className={styles.header}>{title}</div>;
};
export const ModalBody = ({ children }) => {
    return <div className={styles.body}>{children}</div>;
};
export const ModalFooter = ({ cta }) => {
    return <div className={styles.footer}>{cta}</div>;
};
