import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { queries, order, fixTVCrew, timeBreakdown } from '../../components/functions';
import { FigureModal } from '../../components/figure/figure';
import {
    PersonList,
    CrumbList,
    CommaList,
    TabbedList,
    SpacedList,
} from '../../components/list/list';
import {
    Container,
    Page,
    Section,
    SingleDescription,
    SingleName,
    SingleProperty,
} from '../../components/page/page';
import { AmazonLink, IMDBLink } from '../../components/links/links';
import { useStoreContext } from '../../store/storecontext';
import { makeMediaDataSet } from '../../components/mediaset/MediaDataSet';

export const EpisodePage = () => {
    let { tvParam, seasonParam, episodeParam } = useParams();
    let [series, setSeries] = useState(false);
    let [episode, setEpisode] = useState(false);
    let [modal, setModal] = useState(false);

    let [tabbedContent, setTabbedContent] = useState([]);

    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    useEffect(() => {
        setEpisode(false);
        queries([
            [
                'tv/' + tvParam,
                '&append_to_response=credits,videos,images,external_ids&include_image_language=null,en',
            ],
            [
                `tv/${tvParam}/season/${seasonParam}/episode/${episodeParam}`,
                '&append_to_response=credits,videos,images&include_image_language=null,en',
            ],
        ]).then(([tvdata, episodedata]) => {
            //console.log({ tvdata, episodedata, seasonParam });

            tvdata.imdb_id = tvdata.imdb_id ?? tvdata.external_ids?.imdb_id ?? false;
            episodedata.tv_id = tvParam;
            episodedata.tv_name = tvdata.name;
            episodedata.tv_imdb_id = tvdata.imdb_id;

            fixTVCrew(tvdata, episodedata);

            //console.log(episodedata)

            setSeries(tvdata);
            setEpisode(episodedata);
            //console.log(episode)

            const tabset = {
                stills: episodedata?.images?.stills,
            };

            const madeData = makeMediaDataSet({
                media: tabset,
                id: episodedata.id,
                setModal,
                adult,
            });
            // console.log({ collection, tvdata, tabset, madeData });
            setTabbedContent(madeData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seasonParam, tvParam, episodeParam]);

    const opengraph = episode
        ? {
              title: `${series.name} - ${episode.name}`,
              description: episode.overview,
              image: episode.still_path,
              url: `/tv/${tvParam}/${seasonParam}/${episodeParam}}`,
          }
        : false;

    return (
        <Page opengraph={opengraph}>
            {episode && (
                <>
                    <Container>
                        <SingleName>{episode.name}</SingleName>
                        <SingleDescription>
                            <CrumbList
                                list={[
                                    [`/tv/${tvParam}`, episode.tv_name],
                                    [`/tv/${tvParam}/${seasonParam}`, 'Season ' + seasonParam],
                                    ['', 'Episode ' + episodeParam],
                                ]}
                            />

                            <SpacedList>
                                <CommaList>
                                    {episode.runtime && (
                                        <span title={episode.runtime}>
                                            {timeBreakdown(episode.runtime)}
                                        </span>
                                    )}
                                </CommaList>

                                <CommaList>
                                    <IMDBLink id={episode.tv_imdb_id} label="IMDb" />
                                    <AmazonLink
                                        search={`${episode.tv_name} season ${episode.season_number}`}
                                        label="Amazon"
                                    />
                                </CommaList>
                            </SpacedList>

                            <SingleProperty title="Air Date" value={episode.air_date} />
                            <SingleProperty title="Overview" value={episode.overview} />
                        </SingleDescription>
                    </Container>

                    <TabbedList list={tabbedContent} />

                    <Section list={[0]}>
                        <PersonList title="Cast" list={order(episode.credits.cast)} showamount />
                        <PersonList
                            title="Guest Stars"
                            list={order(episode.guest_stars)}
                            showamount
                        />
                        <PersonList title="Crew" list={order(episode.credits.crew)} showamount />
                    </Section>

                    {/* <Section className="section-recommendations" list={[0]}>
         <TVList title="Recommendations" list={episode.recommendations.results} />
         <TVList title="Similar" list={episode.similar.results} />
      </Section> */}
                </>
            )}
            <FigureModal src={modal} setSrc={setModal} />
        </Page>
    );
};
