import React, { useState, useRef } from 'react';
import { useIntersection } from '../lazyintersector';
import styles from './lazyimage.module.css';

export const LazyImageLoader = ({ offset = 0, threshold = 0.15, children }) => {
    const [isInView, setIsInView] = useState(false);
    const imgRef = useRef();
    useIntersection(
        imgRef,
        () => {
            setIsInView(true);
        },
        { offset, threshold }
    );

    return (
        <div className={styles.lazyImageContainer} ref={imgRef}>
            {isInView && children}
        </div>
    );
};
