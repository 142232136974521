import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

(async () => {
  if (!("IntersectionObserver" in window))
    await import("intersection-observer");
})();

ReactDOM.render(<App />, document.getElementById('root'));



setInterval(()=>{
	document.querySelector("body").classList.toggle("marquee-active");
},6000)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
