import React, { useEffect, useState } from 'react';

import { useStoreContext } from '../../store/storecontext';
import cx from 'classnames';
import styles from './overlay.module.css';

const CompareOverlay = ({ position = 'bottom left', type, id, img, title }) => {
    const [{ compare }, setStore] = useStoreContext();
    let [isComparing, setComparing] = useState(false);

    const setToCompare = async (e) => {
        e.preventDefault();
        if (compare.some((o) => o.id === id)) {
            setStore({ compare: compare.filter((o) => o.id !== id) });
        } else {
            setStore({ compare: [...compare, { type, id, img, title }] });
        }
    };

    useEffect(() => {
        setComparing(compare.some((o) => o.id === id));
    }, [compare, id]);

    return (
        <div
            className={cx(styles.overlay, styles.figureOverlay, {
                [styles.compareSelected]: isComparing,
            })}
        >
            <div className={styles.overlayBack}></div>
            <div className={cx(styles.floater, ...position.split(' ').map((o) => styles[o]))}>
                <button className={styles.compareSelectButton} onClick={setToCompare}></button>
            </div>
        </div>
    );
};

export const PersonOverlay = ({ data, position = 'bottom left' }) => {
    return (
        <CompareOverlay
            position={position}
            type="person"
            id={data.id}
            img={data.profile_path}
            title={data.name}
        />
    );
};

export const MovieOverlay = ({ data, position = 'bottom left' }) => {
    return (
        <CompareOverlay
            position={position}
            type="movie"
            id={data.id}
            img={data.poster_path}
            title={data.title}
        />
    );
};

export const TVOverlay = ({ data, position = 'bottom left' }) => {
    return (
        <CompareOverlay
            position={position}
            type="tv"
            id={data.id}
            img={data.poster_path}
            title={data.name}
        />
    );
};
