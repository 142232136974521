import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';
import { query, order, fixTVCrew, uniques, getYear } from '../../components/functions';
import { MediaImage, FigureModal, SingleImage, VideoModal } from '../../components/figure/figure';
import { TVList, PersonList, SeasonList, CommaList, TabbedList } from '../../components/list/list';
import {
    ChildProperty,
    Container,
    Page,
    Section,
    SingleDescription,
    SingleName,
    SingleProperty,
} from '../../components/page/page';
import { TVOverlay } from '../../components/overlay/overlay';
import { AmazonLink, IMDBLink } from '../../components/links/links';

import styles from './profile.module.css';
import { makeMediaDataSet } from '../../components/mediaset/MediaDataSet';
import { useStoreContext } from '../../store/storecontext';

const Genres = ({ genres }) => {
    return genres ? (
        <CommaList>
            {genres.map((o, i) => (
                <Link key={i} to={`/shows/genre/${o.id}/${o.name}`}>
                    {o.name}
                </Link>
            ))}
        </CommaList>
    ) : (
        <></>
    );
};

export const TVProfile = ({ tv, setModal }) => {
    return tv ? (
        <div className={styles.tvInfo}>
            <SingleImage>
                <MediaImage
                    src={tv.poster_path}
                    onClick={() => {
                        setModal(tv.poster_path);
                    }}
                />
                <TVOverlay data={tv} />
            </SingleImage>
            <SingleDescription>
                <SingleName href={`/tv/${tv.id}`}>{tv.name}</SingleName>

                <CommaList>
                    <IMDBLink id={tv.imdb_id} label="IMDb" />
                    <AmazonLink
                        search={`${tv.name} ${tv.first_air_date.slice(0, 4)}`}
                        label="Amazon"
                    />
                </CommaList>

                <SingleProperty title="First Air Date" value={tv.first_air_date} />
                <SingleProperty title="Last Air Date" value={tv.last_air_date} />
                <ChildProperty title="Genres" length={tv.genres?.length}>
                    <Genres genres={tv.genres} />
                </ChildProperty>
                <SingleProperty title="Overview" value={tv.overview} />
            </SingleDescription>
        </div>
    ) : (
        <></>
    );
};

export const TVPage = () => {
    let { tvParam } = useParams();
    let [tv, setTv] = useState(false);
    let [modal, setModal] = useState(false);
    let [tabbedContent, setTabbedContent] = useState([]);
    let [videoModal, setVideoModal] = useState(false);

    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    useEffect(() => {
        setTv(false);
        query(
            'tv/' + tvParam,
            '&append_to_response=credits,videos,images,recommendations,similar,external_ids&include_image_language=null,en'
        ).then(async (tvdata) => {
            fixTVCrew(tvdata, tvdata);
            tvdata.imdb_id = tvdata.imdb_id ?? tvdata.external_ids?.imdb_id ?? false;
            setTv(tvdata);

            //console.log(tvdata);

            const collection = tvdata.belongs_to_collection
                ? await query(`collection/${tvdata.belongs_to_collection.id}`)
                : false;

            const tabset = {
                videos: tvdata?.videos?.results,
                posters: tvdata?.images?.posters,
                backdrops: tvdata?.images?.backdrops,
                logos: tvdata?.images?.logos,
                companies: tvdata?.production_companies,
                networks: tvdata?.networks,
            };

            if (collection) {
                tabset.collection = collection.parts;
            }

            const madeData = makeMediaDataSet({
                media: tabset,
                id: tvdata.id,
                type: 'shows',
                setModal,
                setVideoModal,
                adult,
            });
            // console.log({ collection, tvdata, tabset, madeData });
            setTabbedContent(madeData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tvParam]);

    const opengraph = tv
        ? {
              title: `${tv.name} (${getYear(tv.first_air_date)} - ${getYear(tv.last_air_date)})`,
              description: tv.overview,
              image: tv.poster_path,
              url: '/tv/' + tv.id,
          }
        : false;

    return (
        <Page opengraph={opengraph}>
            {tv && (
                <>
                    <Container>
                        <TVProfile tv={tv} setModal={setModal} />
                    </Container>

                    <TabbedList list={tabbedContent} />

                    <SeasonList
                        list={tv.seasons}
                        tv={tv.id}
                        title={`Seasons (${tv.seasons.length})`}
                    />

                    <Section list={[0]}>
                        <PersonList
                            title="Cast"
                            list={order(tv.credits.cast)}
                            showamount={true}
                            sortorder="tvcast"
                        />
                        <PersonList
                            title="Crew"
                            list={order(tv.credits.crew)}
                            showamount={true}
                            sortorder="tvcrew"
                            jobs={uniques(tv.credits.crew.reduce((r, o) => [...r, o.job], []))}
                        />
                    </Section>

                    <Section list={[0]}>
                        <TVList title="Recommendations" list={tv.recommendations.results} />
                        <TVList title="Similar" list={tv.similar.results} />
                    </Section>
                </>
            )}
            <FigureModal src={modal} setSrc={setModal} />
            <VideoModal src={videoModal} setSrc={setVideoModal} />
        </Page>
    );
};
