import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { MediaImage } from '../figure/figure';

import { Form, Input } from '../forms/forms';
import { slowQueries } from '../functions';

import cx from 'classnames';
import styles from './search.module.css';

// const debounce = (func, timeout = 3000) => {
//    let timer;
//    return (...args) => {
//      clearTimeout(timer);
//      timer = setTimeout(() => { func(...args); }, timeout);
//    };
//  }

export const QuickResultsItem = ({ data, className = '' }) => {
    return (
        <div className={cx(styles.item, className)}>
            {data.type === 'person' ? (
                <a href={`/person/${data.id}`}>
                    <MediaImage src={data.profile_path} />
                    {data.name}
                </a>
            ) : data.type === 'movie' ? (
                <a href={`/movie/${data.id}`}>
                    <MediaImage src={data.poster_path} />
                    {data.title}
                </a>
            ) : (
                <a href={`/tv/${data.id}`}>
                    <MediaImage src={data.poster_path} />
                    {data.name}
                </a>
            )}
        </div>
    );
};

const SearchForm = () => {
    const { searchParam } = useParams();

    const inputref = useRef();

    const [search, setSearch] = useState('');
    const [searchTo, setSearchto] = useState(false);
    //    const [linkTo,setLinkto] = useState(false);
    const [quickresults, setQuickresults] = useState([]);
    const [quickopen, setQuickopen] = useState(false);
    const [quickchoice] = useState(false);

    useEffect(() => {
        if (searchParam !== undefined) {
            setSearchto(false);
            setSearch(searchParam);
        }
    }, [searchParam]);

    const searchHandler = (e) => {
        setSearch(e.target.value);
    };
    const blurHandler = (e) => {
        setTimeout(() => {
            setQuickopen(false);
        }, 200);
    };
    const focusHandler = (e) => {
        setTimeout(() => {
            setQuickopen(true);
        }, 100);
    };
    const submitHandler = (e) => {
        if (search !== '') {
            setSearchto(true);
            setQuickopen(false);
            setTimeout(() => {
                setSearchto(false);
            }, 1);
        }
    };

    const quickQuery = (search) => {
        if (search !== '') {
            slowQueries([
                ['search/person', '&page=1&region=US&query=' + search],
                ['search/movie', '&page=1&region=US&query=' + search],
                ['search/tv', '&page=1&region=US&query=' + search],
            ]).then((d) => {
                const person = d[0].results.map((d) => ({ ...d, type: 'person' }));
                const movie = d[1].results.map((d) => ({ ...d, type: 'movie' }));
                const tv = d[2].results.map((d) => ({ ...d, type: 'tv' }));

                const results = [[], [], []];

                do {
                    const p = person.shift();
                    const m = movie.shift();
                    const t = tv.shift();
                    if (p) results[0].push(p);
                    if (m) results[1].push(m);
                    if (t) results[2].push(t);
                } while (
                    (person.length || movie.length || tv.length) &&
                    results.flat().length < 10
                );

                // console.log(results.flat())
                setQuickresults(results.flat());
            });
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            quickQuery(search);
        }, 500);
        return () => clearTimeout(timer);
    }, [search]);

    // useEffect(()=>{
    //    const getQuickId = (num) => quickresults.length ? wrapNumber(quickresults.length)(quickchoice+num) : 0;
    //    const keyHandler = (e) => {
    //       switch(e.code) {
    //          case "ArrowDown":
    //             setQuickchoice(getQuickId(1));
    //             break;
    //          case "ArrowUp":
    //             setQuickchoice(getQuickId(-1));
    //             break;
    //          case "Enter":
    //       }
    //    }
    //    inputref.current.addEventListener("keydown", keyHandler);
    //    return () => inputref.current.removeEventListener("keydown", keyHandler);
    // }, [quickresults,quickchoice,inputref])

    return (
        <>
            {searchTo && <Navigate replace to={`/search/${search}`} />}
            {/* { linkTo && <Navigate replace to={linkTo} /> } */}
            <Form id="search-main-form" className={styles.hotdog} onSubmit={submitHandler}>
                <Input
                    type="search"
                    id="search-main-input"
                    value={search}
                    onChange={searchHandler}
                    onBlur={blurHandler}
                    onFocus={focusHandler}
                    placeholder="Search for something"
                    ref={inputref}
                />
            </Form>
            {quickopen && quickresults.length > 0 && (
                <div className={styles.droplist}>
                    {quickresults.map((o, i) => (
                        <QuickResultsItem
                            data={o}
                            key={i}
                            className={cx({ active: quickchoice === i })}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export const Search = () => {
    return (
        <Routes>
            <Route path="/search/:searchParam" element={<SearchForm />} />
            <Route path="/*" element={<SearchForm />} />
        </Routes>
    );
};
