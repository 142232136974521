import { useEffect } from 'react';

let listenerCallbacks = new WeakMap();

let observer;

const handleIntersections = (entries) => {
    entries.forEach(entry => {
        if (listenerCallbacks.has(entry.target)) {
            let cb = listenerCallbacks.get(entry.target);
            
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
                observer.unobserve(entry.target);
                listenerCallbacks.delete(entry.target);
                cb();
            }
        }
    });
}

const getIntersectionObserver = (offset=0,threshold=0) => {
    if (observer === undefined) {
        observer = new IntersectionObserver(handleIntersections, {
            rootMargin: offset+'px',
            threshold,
        });
    }
    return observer;
}

export const useIntersection = (elem, callback, options) => {
    useEffect(() => {
        let target = elem.current;
        let observer = getIntersectionObserver(options?.offset);
        listenerCallbacks.set(target, callback);
        observer.observe(target);
        
        return () => {
            listenerCallbacks.delete(target);
            observer.unobserve(target);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}