import React, { useEffect, useState } from 'react';

import { Container, Page } from '../components/page/page';
import { query } from '../components/functions';
import { FigureModal } from '../components/figure/figure';
import { useParams } from 'react-router-dom';
import { EpisodeList, SeasonList } from '../components/list/list';
import { PersonProfile } from './profiles/Person';
import { TVProfile } from './profiles/TV';

export const CreditsPage = () => {
    let { creditId } = useParams();
    let [credits, setCredits] = useState({});
    let [modal, setModal] = useState(false);

    useEffect(() => {
        query(`credit/${creditId}`, ``).then((d) => {
            // console.log(d);
            setCredits(d);
        });
    }, [creditId]);

    return (
        <Page>
            {credits.media && (
                <>
                    <Container>
                        <div className="grid">
                            <div className="col-xs-12 col-md-6">
                                <PersonProfile person={credits.person} setModal={setModal} />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <TVProfile tv={credits.media} setModal={setModal} />
                            </div>
                        </div>
                    </Container>
                    <Container>
                        <h3>{credits.job}</h3>
                        {credits.media.character && credits.media.character !== '' && (
                            <div>{credits.media.character}</div>
                        )}
                    </Container>
                    <div>
                        {credits.media.episodes.length > 0 && (
                            <EpisodeList
                                list={credits.media.episodes}
                                tv={credits.media.id}
                                title={`${credits.media.episodes.length} Episodes`}
                            />
                        )}
                        {credits.media.seasons.length > 0 && (
                            <SeasonList
                                list={credits.media.seasons}
                                tv={credits.media.id}
                                title="season"
                            />
                        )}
                    </div>
                    {modal && <FigureModal src={modal} setSrc={setModal} />}
                </>
            )}
        </Page>
    );
};
