import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { Page, Container, HeaderList, HeaderLink } from '../../components/page/page';
import {
    ComparePersonList,
    CompareMovieList,
    CompareTVList,
    CompareRow,
} from '../../components/list/list';

import { useStoreContext } from '../../store/storecontext';
import { CompareMovies } from './CompareMovies';
import { CompareShows } from './CompareShows';
import { ComparePeople } from './ComparePeople';

import styles from './compare.module.css';

const findPeople = (compare) => compare.filter((o) => o.type === 'person');
const findMovies = (compare) => compare.filter((o) => o.type === 'movie');
const findTV = (compare) => compare.filter((o) => o.type === 'tv');

const MainComparePage = ({ compareTypes }) => {
    const person = findPeople(compareTypes);
    const movie = findMovies(compareTypes);
    const tv = findTV(compareTypes);
    return (
        <>
            <Container>
                <p>
                    Have you selected some things below? Then choose a section above and compare
                    them. This main page is more or less clerical right now.
                </p>
            </Container>
            {person.length + movie.length + tv.length === 0 ? (
                <Container>
                    <p>Time to go find some things to compare. This page looks empty.</p>
                </Container>
            ) : (
                <>
                    <CompareRow list={[0]}>
                        <ComparePersonList list={person} emptywarning={true} />
                    </CompareRow>
                    <CompareRow list={[0]}>
                        <CompareMovieList list={movie} emptywarning={true} />
                    </CompareRow>
                    <CompareRow list={[0]}>
                        <CompareTVList list={tv} emptywarning={true} />
                    </CompareRow>
                </>
            )}
        </>
    );
};

export const ComparePage = () => {
    const [{ compare }] = useStoreContext();

    return (
        <Page className={styles.comparePage}>
            <HeaderList>
                <HeaderLink to=".">Main</HeaderLink>
                <HeaderLink to="person">People</HeaderLink>
                <HeaderLink to="movie">Movies</HeaderLink>
                <HeaderLink to="tv">Shows</HeaderLink>
            </HeaderList>
            <Routes>
                <Route path="/" element={<MainComparePage compareTypes={compare} />} />
                <Route path="person/" element={<ComparePeople compare={findPeople(compare)} />} />
                <Route
                    path="person/:compareOption"
                    element={<ComparePeople compare={findPeople(compare)} />}
                />
                <Route path="movie/" element={<CompareMovies compare={findMovies(compare)} />} />
                <Route
                    path="movie/:compareOption"
                    element={<CompareMovies compare={findMovies(compare)} />}
                />
                <Route path="tv/" element={<CompareShows compare={findTV(compare)} />} />
                <Route
                    path="tv/:compareOption"
                    element={<CompareShows compare={findTV(compare)} />}
                />
            </Routes>
        </Page>
    );
};
