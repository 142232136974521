import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { queries, order, fixTVCrew, getYear } from '../../components/functions';
import { MediaImage, FigureModal, SingleImage, VideoModal } from '../../components/figure/figure';
import {
    PersonList,
    EpisodeList,
    CrumbList,
    TabbedList,
    CommaList,
} from '../../components/list/list';
import {
    Container,
    Page,
    Section,
    SingleDescription,
    SingleProperty,
} from '../../components/page/page';
import { AmazonLink, IMDBLink } from '../../components/links/links';
import { makeMediaDataSet } from '../../components/mediaset/MediaDataSet';
import { useStoreContext } from '../../store/storecontext';

export const SeasonPage = () => {
    let { tvParam, seasonParam } = useParams();
    let [series, setSeries] = useState(false);
    let [season, setSeason] = useState(false);
    let [modal, setModal] = useState(false);

    let [videoModal, setVideoModal] = useState(false);
    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    let [tabbedContent, setTabbedContent] = useState([]);

    useEffect(() => {
        setSeason(false);
        queries([
            [
                'tv/' + tvParam,
                '&append_to_response=credits,videos,images,external_ids&include_image_language=null,en',
            ],
            [
                `tv/${tvParam}/season/${seasonParam}`,
                '&append_to_response=credits,details,aggregate_credits,videos,images&include_image_language=null,en',
            ],
        ]).then(async ([tvdata, seasondata]) => {
            tvdata.imdb_id = tvdata.imdb_id ?? tvdata.external_ids?.imdb_id ?? false;
            seasondata = {
                ...seasondata,
                tv_id: tvParam,
                tv_name: tvdata.name,
                tv_imdb_id: tvdata.imdb_id,
                credits: seasondata.aggregate_credits,
            };

            // console.log(d);

            fixTVCrew(tvdata, seasondata);

            setSeries(tvdata);
            setSeason(seasondata);

            const tabset = {
                videos: seasondata?.videos?.results,
                posters: seasondata?.images?.posters,
                backdrops: seasondata?.images?.backdrops,
                logos: seasondata?.images?.logos,
            };

            const madeData = makeMediaDataSet({
                media: tabset,
                id: seasondata.id,
                setModal,
                setVideoModal,
                adult,
            });
            // console.log({ collection, moviedata, tabset, madeData });
            setTabbedContent(madeData);

            //console.log({ tvdata, seasondata });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seasonParam, tvParam]);

    const opengraph = season
        ? {
              title: `${series.name} - ${season.name}`,
              description: season.overview,
              image: season.poster_path,
              url: `/tv/${tvParam}/${seasonParam}}`,
          }
        : false;

    return (
        <Page opengraph={opengraph}>
            {season && (
                <>
                    <Container>
                        <SingleImage>
                            <MediaImage
                                src={season.poster_path}
                                onClick={() => {
                                    setModal(season.poster_path);
                                }}
                            />
                        </SingleImage>
                        <SingleDescription>
                            <CrumbList
                                list={[
                                    ['/tv/' + season.tv_id, season.tv_name],
                                    ['', season.name],
                                ]}
                            />

                            <CommaList>
                                <IMDBLink id={season.tv_imdb_id} label="IMDb" />
                                <AmazonLink
                                    search={`${season.tv_name} season ${season.season_number}`}
                                    label="Amazon"
                                />
                            </CommaList>

                            <SingleProperty title="Year" value={getYear(season.air_date)} />
                            <SingleProperty
                                title="Overview"
                                value={season.overview === '' ? series.overview : season.overview}
                            />
                        </SingleDescription>
                    </Container>

                    <TabbedList list={tabbedContent} />

                    <EpisodeList
                        list={season.episodes}
                        tv={tvParam}
                        title={`${season.episodes.length} Episodes`}
                    />

                    <Section list={[0]}>
                        <PersonList
                            title="Cast"
                            list={order(season.credits.cast)}
                            showamount={true}
                        />
                        <PersonList
                            title="Crew"
                            list={order(season.credits.crew)}
                            showamount={true}
                        />
                    </Section>
                </>
            )}
            <FigureModal src={modal} setSrc={setModal} />
            <VideoModal src={videoModal} setSrc={setVideoModal} />
        </Page>
    );
};
