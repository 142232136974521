import React from 'react';
import { useStoreContext } from '../../store/storecontext';

import styles from './badge.module.css';

export const Badge = ({ value }) => {
    return <>{value !== 0 && value !== '' && <div className={styles.badge}>{value}</div>}</>;
};

export const CompareBadge = () => {
    const [{ compare }] = useStoreContext();

    return <Badge value={compare.length} />;
};
