import React from 'react';
import styles from './video.module.css';

export const makeVideoURL = (videoId) => `https://www.youtube.com/embed/${videoId}`;
export const makeVideoThumbnailURL = (videoId) => `https://img.youtube.com/vi/${videoId}/0.jpg`;

export const VideoThumb = ({ movie = {}, showModal }) => {
    const handleClick = (e) => {
        e.preventDefault();
        // console.log("VideoThumb.handleClick", movie)
        showModal(movie);
    };
    return (
        <div className={styles.videoThumb}>
            <button onClick={handleClick} type="button">
                <img src={makeVideoThumbnailURL(movie.key)} alt={`${movie.site} ${movie.type}`} />
            </button>
        </div>
    );
};

export const VideoThumbs = ({ movies = [], showModal }) => {
    return (
        <div className={styles.videoThumbs}>
            {movies.map((movie) => (
                <VideoThumb key={movie.id} movie={movie} {...{ showModal }} />
            ))}
        </div>
    );
};
