import React from 'react';
import './App.css';
import { StoreContextProvider } from './store/storecontext';
import { SiteRoutes } from './routes/Routes';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GA_ID || '');
const App = () => {
    return (
        <StoreContextProvider>
            <SiteRoutes />
        </StoreContextProvider>
    );
};

export default App;
