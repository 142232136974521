import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
    CompareFigure,
    EpisodeFigure,
    MediaImage,
    MovieFigure,
    PersonFigure,
    SeasonFigure,
    TVCreditsFigure,
    TVFigure,
} from '../figure/figure';
import { SelectControl } from '../forms/forms';
import { Container, Section, SectionHeader } from '../page/page';
import { sortArrayOfObjects } from '../functions';
//import { order,reorder } from '../components/functions';

import cx from 'classnames';
import styles from './list.module.css';

const sortings = {
    recentmovies: [
        ['', 'Most Known For'],
        ['ratingDESC', 'Highest Rating'],
        ['ratingASC', 'Lowest Rating'],
        ['releasedateDESC', 'Latest Release Date'],
        ['releasedateASC', 'Oldest Release Date'],
    ],
    moviecast: [
        ['', 'Relevance'],
        ['personnameASC', 'Actor Alphabetical'],
        ['personcharacterASC', 'Character Alphabetical'],
    ],
    moviecrew: [
        ['jobASC', 'Job'],
        ['', 'Relevance'],
        ['personnameASC', 'Name Alphabetical'],
    ],
    personcast: [
        ['', 'Relevance'],
        ['movietitleASC', 'Title'],
        ['personcharacterASC', 'Character Alphabetical'],
        ['releasedateDESC', 'Most Recent First'],
        ['releasedateASC', 'Oldest First'],
    ],
    personcrew: [
        ['', 'Relevance'],
        ['movietitleASC', 'Title'],
        ['releasedateDESC', 'Most Recent First'],
        ['releasedateASC', 'Oldest First'],
    ],
    tvcast: [
        ['', 'Relevance'],
        ['personnameASC', 'Actor Alphabetical'],
        ['personcharacterASC', 'Character Alphabetical'],
        ['firstairdateDESC', 'Latest First Air Date'],
        ['firstairdateASC', 'Oldest First Air Date'],
        ['episodesDESC', 'Most Episodes'],
    ],
    tvcrew: [
        ['', 'Relevance'],
        ['personnameASC', 'Name Alphabetical'],
    ],
};

export const majorCrewOrder = [
    'Director',
    'Writer',
    'Screenplay',
    'Story',
    'Novel',
    'Created By',
    'Characters',
    'Producer',
    'Co-Producer',
    'Executive Producer',
    'Original Music Composer',
    'Director of Photography',
    'Cinematography',
    'Editor',
    'Casting',
    'Production Design',
    'Art Direction',
];

const sortrating = (dir) => (a, b) => {
    const da = a.vote_count < 10 ? a.vote_average / 10 : a.vote_average;
    const db = b.vote_count < 10 ? b.vote_average / 10 : b.vote_average;
    return dir === 'ASC' ? da - db : db - da;
};
const sortdate = (dir, prop) => (a, b) => {
    const da = new Date(a[prop] || '1800-01-01');
    const db = new Date(b[prop] || '1800-01-01');
    return dir === 'ASC' ? da - db : db - da;
};
const sortprop = (prop, dir) => (a, b) => {
    const da = a[prop];
    const db = b[prop];
    if (da < db) {
        return dir === 'ASC' ? -1 : 1;
    }
    if (da > db) {
        return dir === 'ASC' ? 1 : -1;
    }
    return 0;
};

const sortReducer = (setList, action) => {
    switch (action) {
        case 'ratingASC':
            return [...setList].sort(sortrating('ASC'));
        case 'ratingDESC':
            return [...setList].sort(sortrating('DESC'));
        case 'releasedateASC':
            return [...setList].sort(sortdate('ASC', 'release_date'));
        case 'releasedateDESC':
            return [...setList].sort(sortdate('DESC', 'release_date'));

        case 'firstairdateASC':
            return [...setList].sort(sortdate('ASC', 'first_air_date'));
        case 'firstairdateDESC':
            return [...setList].sort(sortdate('DESC', 'first_air_date'));
        case 'episodesDESC':
            return [...setList].sort(sortdate('DESC', 'episode_count'));

        case 'personnameASC':
            return [...setList].sort(sortprop('name', 'ASC'));
        case 'personnameDESC':
            return [...setList].sort(sortprop('name', 'DESC'));
        case 'personcharacterASC':
            return [...setList].sort(sortprop('character', 'ASC'));
        case 'personcharacterDESC':
            return [...setList].sort(sortprop('character', 'DESC'));
        case 'movietitleASC':
            return [...setList].sort(sortprop('title', 'ASC'));
        case 'movietitleDESC':
            return [...setList].sort(sortprop('title', 'DESC'));

        case 'jobASC':
            return sortArrayOfObjects(majorCrewOrder)([...setList], 'job');

        default:
            return [...setList];
    }
};
const filterReducer = (setList, action, value) => {
    switch (action) {
        case 'job':
            return [...setList].filter((o) => o.jobs.some((o) => o === value));
        case 'character':
            return [...setList].filter((o) => o.character === value);

        default:
            return [...setList];
    }
};

export const FigureList = ({
    type,
    Figure,
    to = (o) => `/${type}/${o.id}`,
    title = '',
    list = [],
    options = [],
    filters = [],
    showamount = false,
    className = '',
    defaultSort = '',
    badge = '',
    selectId,
}) => {
    let listref = useRef();

    let [thelist, setThelist] = useState(list);

    let [sort, setSort] = useState(defaultSort);
    let [filter, setFilter] = useState('');

    useEffect(() => {
        setThelist(sortReducer([...list], defaultSort));
    }, [list, defaultSort]);

    return list.length > 0 ? (
        <Section className={className} list={thelist}>
            {title !== '' && (
                <ListHeader title={title} list={thelist} shown={showamount}>
                    {options.length > 0 && (
                        <SelectControl
                            id={`select-${type}-${title.replace(/\s/, '-')}`}
                            value={sort}
                            options={options}
                            onChange={(e) => {
                                let v = e.target.value;
                                setSort(v);
                                setThelist(sortReducer(list, v));
                                listref.current.scrollTo(0, 0);
                            }}
                        />
                    )}
                    {filters.length > 1 && (
                        <SelectControl
                            id={`filter-${type}-${title.replace(/\s/, '-')}`}
                            value={filter}
                            options={[['', 'Unfiltered'], ...filters]}
                            onChange={(e) => {
                                let v = e.target.value;
                                setFilter(v);
                                const filt = filterReducer(list, ...v.split('\\t'));
                                setThelist(filt);
                                listref.current.scrollTo(0, 0);
                            }}
                        />
                    )}
                </ListHeader>
            )}
            <ListMap {...{ list: thelist, type, to, Figure, selectId, ref: listref }} />
        </Section>
    ) : (
        <EmptyList {...{ title, type, list }} />
    );
};

export const SimpleFigureList = ({
    list = [],
    type,
    className = '',
    Figure,
    to = (o) => `/${type}/${o.id}`,
    defaultSort = '',
}) => {
    let [thelist, setThelist] = useState(list);

    useEffect(() => {
        setThelist(sortReducer([...list], defaultSort));
    }, [list, defaultSort]);
    return (
        <ul className={cx(styles.list, className, { [styles[type]]: type !== '' })}>
            {thelist.map((o, i) => (
                <ListItem key={i}>
                    <Link to={to(o)}>
                        <Figure data={o} />
                    </Link>
                </ListItem>
            ))}
        </ul>
    );
};

export const EmptyList = ({ title = '', type, list = [0], className = '' }) => {
    return (
        <Section className={cx(styles[type], className)} list={list}>
            {title !== '' && <SectionHeader title={title} shown={false} list={list} />}
            <div>It doesn't look like there's any {type} yet.</div>
        </Section>
    );
};

export const ListItem = ({ className = '', children }) => {
    return <li className={cx(styles.listItem, className)}>{children}</li>;
};
export const ListMap = forwardRef(
    ({ list = [], type = '', to = () => {}, Figure, selectId }, ref) => {
        return (
            <ul className={cx(styles.list, { [styles[type]]: type !== '' })} ref={ref}>
                {list.map((o, i) => (
                    <ListItem
                        key={`${i}-${o.id}`}
                        className={cx({ [styles.active]: selectId === o.id })}
                    >
                        <Link to={to(o)}>
                            <Figure data={o} />
                        </Link>
                    </ListItem>
                ))}
            </ul>
        );
    }
);

export const ListHeader = ({ list = [], title = '', shown = false, children }) => {
    return (
        <Container>
            <div className={styles.listHeader}>
                <div className={styles.flexStretch}>
                    <h2>
                        {title} {shown && `(${list.length})`}
                    </h2>
                </div>
                <div className={styles.flex}>{children}</div>
            </div>
        </Container>
    );
};

export const TabbedList = ({ list = [], className = '' }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const titles = list?.map((o) => o.title) || [];
    const contents = list?.map((o) => o.content) || [];

    const handleButtonClick = (id) => (e) => {
        e.preventDefault();
        setCurrentTab(id);
    };
    return titles.length > 0 ? (
        <div className={cx(styles.tabbedList, className)}>
            <div className={styles.tabs}>
                {titles.map((o, i) => (
                    <button
                        key={i}
                        onClick={handleButtonClick(i)}
                        className={cx(styles.content, {
                            [styles.active]: i === currentTab,
                        })}
                    >
                        {o}
                    </button>
                ))}
            </div>
            <div className={styles.contents}>
                {contents.map((o, i) => (
                    <div
                        key={i}
                        className={cx(styles.content, {
                            [styles.hidden]: i !== currentTab,
                        })}
                    >
                        {o}
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <></>
    );
};

export const PersonList = ({
    title = '',
    sortorder = '',
    list = [],
    showamount = false,
    defaultSort = '',
    jobs = [],
}) => {
    let [itemList, setList] = useState(list);
    let [filterList, setFilterList] = useState([]);

    useEffect(() => {
        setList([...list.filter((o) => o.profile_path), ...list.filter((o) => !o.profile_path)]);

        if (list[0]?.jobs) {
            let jobs = list.reduce((r, o) => [...r, ...o.jobs], []);
            let j = [...new Set(jobs)].sort().map((o) => ['job\\t' + o, o]);
            setFilterList(j);
        }
    }, [list]);

    return (
        <FigureList
            title={title}
            list={itemList}
            showamount={showamount}
            type="person"
            options={sortings[sortorder]}
            filters={filterList}
            defaultSort={defaultSort}
            Figure={PersonFigure}
        />
    );
};

export const CompareList = ({ children }) => {
    return <div className={styles.compareList}>{children}</div>;
};

export const CompareRow = ({ list = [], children }) => {
    return (
        <Section className={styles.compareRow} list={list}>
            {children}
        </Section>
    );
};

export const ComparePersonList = ({
    list = [],
    title = '',
    emptywarning = false,
    className = styles.compareList,
    Figure = CompareFigure,
}) => {
    return (
        <>
            {list.length > 0 ? (
                <SimpleFigureList
                    list={list}
                    title={title}
                    type="person"
                    Figure={Figure}
                    className={cx(className, styles.peopleCompare)}
                />
            ) : (
                emptywarning && <EmptyList title={title} type="people" />
            )}
        </>
    );
};

export const CompareMovieList = ({
    list = [],
    title = '',
    emptywarning = false,
    className = styles.compareList,
    Figure = CompareFigure,
}) => {
    return (
        <>
            {list.length > 0 ? (
                <SimpleFigureList
                    list={list}
                    title={title}
                    type="movie"
                    Figure={Figure}
                    className={cx(className, styles.movieCompare)}
                />
            ) : (
                emptywarning && <EmptyList title={title} type="movies" />
            )}
        </>
    );
};

export const CompareTVList = ({
    list = [],
    title = '',
    emptywarning = false,
    className = styles.compareList,
    Figure = CompareFigure,
}) => {
    return (
        <>
            {list.length > 0 ? (
                <SimpleFigureList
                    list={list}
                    title={title}
                    type="tv"
                    Figure={Figure}
                    className={cx(className, styles.tvCompare)}
                />
            ) : (
                emptywarning && <EmptyList title={title} type="shows" />
            )}
        </>
    );
};

export const ComparePersonResultsList = ({ list = [] }) => {
    // console.log('compare person results list', list);
    return (
        <>
            {list.map((o, i) => (
                <Section key={i} list={[...o.movie, ...o.tv]} className={styles.compareRow}>
                    {/* <div className="display-flex overflow-x-auto"> */}
                    <ComparePersonList list={o.people} />
                    <CompareMovieList list={o.movie} />
                    <CompareTVList list={o.tv} />
                    {/* </div> */}
                </Section>
            ))}
        </>
    );
};

export const CompareMovieResultsList = ({ list = [] }) => {
    return (
        <>
            {list.map((o, i) => (
                <Section key={i} list={o.people} className={styles.compareRow}>
                    {/* <div key={i} className="section section-compare display-flex overflow-x-auto"> */}
                    <CompareMovieList list={o.movie} />
                    <ComparePersonList list={o.people} />
                    {/* </div> */}
                </Section>
            ))}
        </>
    );
};

export const CompareTVResultsList = ({ list = [] }) => {
    return (
        <>
            {list.map((o, i) => (
                <Section key={i} list={o.people} className={styles.compareRow}>
                    {/* <div key={i} className="section section-compare display-flex overflow-x-auto"> */}
                    <CompareTVList list={o.tv} />
                    <ComparePersonList list={o.people} />
                    {/* </div> */}
                </Section>
            ))}
        </>
    );
};

export const MovieList = ({
    title = '',
    sortorder = '',
    list = [],
    showamount = false,
    className = '',
    selectId = false,
    jobs = [],
}) => {
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        let sorted = [];
        if (list[0]?.jobs) {
            const jobs = list.reduce((r, o) => [...r, ...o.jobs], []);
            sorted = [...new Set(jobs)].sort().map((o) => ['job\\t' + o, o]);
            // console.log(jobs,sorted)
        }
        if (list[0]?.character) {
            const characters = list.reduce((r, o) => (o.character ? [...r, o.character] : r), []);
            sorted = [...new Set(characters)].sort().map((o) => ['character\\t' + o, o]);
            // console.log(characters,sorted)
        }
        setFilterList(sorted);
    }, [list]);

    return (
        <FigureList
            title={title}
            list={sortReducer([...list], sortorder)}
            showamount={showamount}
            type="movie"
            Figure={MovieFigure}
            options={sortings[sortorder]}
            filters={filterList}
            className={styles[className]}
            selectId={selectId}
        />
    );
};

export const TVList = ({
    title = '',
    sortorder = '',
    list = [],
    showamount = false,
    className = '',
    selectId = false,
}) => {
    return (
        <FigureList
            title={title}
            list={sortReducer([...list], sortorder)}
            showamount={showamount}
            type="tv"
            Figure={TVFigure}
            className={styles[className]}
            selectId={selectId}
        />
    );
};

export const TVCreditsList = ({ title = '', sortorder = '', list = [], showamount = false }) => {
    return (
        <FigureList
            title={title}
            list={sortReducer([...list], sortorder)}
            showamount={showamount}
            options={sortings[sortorder]}
            type="tv"
            to={(o) => `/credit/${o.credit_id}`}
            className={styles.tvCreditsList}
            Figure={TVCreditsFigure}
        />
    );
};

export const SeasonList = ({ tv, title = '', list = [], showamount = false }) => {
    return (
        <FigureList
            className={styles.listSeason}
            title={title}
            list={list}
            showamount={showamount}
            type="season"
            to={(o) => `/tv/${tv}/${o.season_number}`}
            Figure={SeasonFigure}
        />
    );
};

export const EpisodeList = ({ tv, title = '', list = [], showamount = false }) => {
    return (
        <FigureList
            className={styles.listEpisode}
            title={title}
            list={list}
            showamount={showamount}
            type="episode"
            to={(o) => `/tv/${tv}/${o.season_number}/${o.episode_number}`}
            Figure={EpisodeFigure}
            badge="episode_count"
        />
    );
};

export const CommaList = ({ className = '', children }) => {
    return <div className={cx(styles.commaList, className)}>{children}</div>;
};

export const SpacedList = ({ className = '', children }) => {
    return <div className={cx(styles.spacedList, className)}>{children}</div>;
};

export const CommaItem = ({ href = false, onClick = false, children }) => {
    return href !== false ? (
        <Link to={href} className={cx(styles.commaItem)}>
            {children}
        </Link>
    ) : onClick !== false ? (
        <button onClick={onClick} className={cx(styles.commaItem)}>
            {children}
        </button>
    ) : (
        <span className={cx(styles.commaItem)}>{children}</span>
    );
};

export const CrumbList = ({ list }) => {
    return (
        <ul className={cx(styles.crumbs)}>
            {list.map((o, i) => (
                <li key={i}>{o[0] !== '' ? <Link to={o[0]}>{o[1]}</Link> : <span>{o[1]}</span>}</li>
            ))}
        </ul>
    );
};

export const ThumbsList = ({ list = [], setModal = () => {} }) => {
    return (
        <ul className={styles.thumbs}>
            {list.length > 0 &&
                list.map((o, i) => (
                    <li
                        key={i}
                        className={styles.thumb}
                        onClick={() => {
                            setModal(o.file_path);
                        }}
                    >
                        <MediaImage src={o.file_path} />
                    </li>
                ))}
        </ul>
    );
};

export const CompaniesList = ({ companies, type = 'movies', sort = 'company' }) => {
    return (
        <div className={styles.companies}>
            {companies.map((o, i) => (
                <a className={styles.company} key={i} href={`/${type}/${sort}/${o.id}/${o.name}`}>
                    {o.logo_path ? (
                        <MediaImage src={o.logo_path} quality={154} />
                    ) : (
                        <span className={styles.companyCircle}>{o.name}</span>
                    )}
                </a>
            ))}
        </div>
    );
};
