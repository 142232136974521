import React, { useEffect } from 'react';

import { BrowserRouter, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from '../components/scrolltotop';
import { Header, Stretch, NoStretch } from '../components/page/page';
import { Search } from '../components/search/search';

import { SearchPage } from './Search';
import { MoviePage } from './profiles/Movie';
import { MoviesPage } from './Movies';
import { CreditsPage } from './Credits';
import { PersonPage } from './profiles/Person';
import { EpisodePage } from './profiles/Episode';
import { SeasonPage } from './profiles/Season';
import { TVPage } from './profiles/TV';
import { RecentPage } from './Recent';
import { ComparePage } from './compare/Compare';
import { ShowsPage } from './Shows';
import { SettingsPage } from './Settings';

import homeicon from '../img/home.svg';
import checkicon from '../img/square-rounded-checkmark.svg';
import gearicon from '../img/gear.svg';
import { Icon } from '../components/links/links';
import ReactGA from 'react-ga4';
import { CompareBadge } from '../components/badge/badge';

const GALoader = () => {
    const location = useLocation();
    useEffect(() => {
        // console.log(location);
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: location.pathname.split('/').join(' '),
        });
    }, [location]);
    return <></>;
};

export const SiteRoutes = () => {
    return (
        <BrowserRouter>
            <GALoader />
            <Header>
                <NoStretch>
                    <NavLink to="/">
                        <Icon src={homeicon} />
                    </NavLink>
                </NoStretch>
                <Stretch style={{ padding: '0 0.5em', flexBasis: '100%' }}>
                    <Search />
                </Stretch>
                <NoStretch>
                    <NavLink to="/compare">
                        <Icon src={checkicon} />
                    </NavLink>
                    <CompareBadge />
                </NoStretch>
                <NoStretch>
                    <NavLink to="/settings">
                        <Icon src={gearicon} />
                    </NavLink>
                </NoStretch>
            </Header>
            <ScrollToTop>
                <Routes>
                    <Route path="/search/:searchParam" element={<SearchPage />} />
                    <Route path="/movie/:movieParam" element={<MoviePage />} />
                    <Route path="/movies/*" element={<MoviesPage />} />
                    <Route path="/credit/:creditId" element={<CreditsPage />} />
                    <Route path="/person/:personParam" element={<PersonPage />} />
                    <Route
                        path="/tv/:tvParam/:seasonParam/:episodeParam"
                        element={<EpisodePage />}
                    />
                    <Route path="/tv/:tvParam/:seasonParam" element={<SeasonPage />} />
                    <Route path="/tv/:tvParam" element={<TVPage />} />
                    <Route path="/shows/*" element={<ShowsPage />} />
                    <Route path="/compare/*" element={<ComparePage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/" element={<RecentPage />} />
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    );
};
