import React from 'react';

import { CompaniesList, MovieList } from '../list/list';
import { VideoThumbs } from '../video/video';
import { FigureGallery } from '../figure/figure';

export const makeMediaDataSet = ({
    media = {},
    id,
    type,
    setModal = () => {},
    setVideoModal = () => {},
    adult,
}) => {
    const videos = media.videos;
    const posters = media.posters;
    const backdrops = media.backdrops;
    const logos = media.logos;
    const collection = media.collection;
    const stills = media.stills;
    const companies = media.companies;
    const networks = media.networks;

    return [
        ...(collection?.length > 0
            ? [
                  {
                      title: 'Collection',
                      length: collection.length,
                      items: collection,
                      content:
                          type === 'movies' ? (
                              <MovieList
                                  className="movieCollection"
                                  sortorder="releasedateASC"
                                  list={collection}
                              />
                          ) : (
                              <></>
                          ),
                  },
              ]
            : []),
        ...(videos?.length > 0
            ? [
                  {
                      title: 'Videos',
                      length: videos.length,
                      items: videos,
                      content: <VideoThumbs movies={videos} showModal={setVideoModal} />,
                  },
              ]
            : []),
        ...(posters?.length > 0
            ? [
                  {
                      title: 'Posters',
                      length: posters.length,
                      items: posters,
                      content: (
                          <FigureGallery
                              className={'posters'}
                              images={posters}
                              setModal={setModal}
                              adultHandler={(o) => o.adult && !adult}
                          />
                      ),
                  },
              ]
            : []),
        ...(backdrops?.length > 0
            ? [
                  {
                      title: 'Backdrops',
                      length: backdrops.length,
                      items: backdrops,
                      content: (
                          <FigureGallery
                              className={'backdrops'}
                              images={backdrops}
                              setModal={setModal}
                              adultHandler={(o) => o.adult && !adult}
                          />
                      ),
                  },
              ]
            : []),
        ...(logos?.length > 0
            ? [
                  {
                      title: 'Logos',
                      length: logos.length,
                      items: logos,
                      content: (
                          <FigureGallery
                              className={'logos'}
                              images={logos}
                              setModal={setModal}
                              adultHandler={(o) => o.adult && !adult}
                          />
                      ),
                  },
              ]
            : []),
        ...(stills?.length > 0
            ? [
                  {
                      title: 'Stills',
                      length: stills.length,
                      items: stills,
                      content: (
                          <FigureGallery
                              className={'stills'}
                              images={stills}
                              setModal={setModal}
                              adultHandler={(o) => o.adult && !adult}
                          />
                      ),
                  },
              ]
            : []),
        ...(companies?.length > 0
            ? [
                  {
                      title: 'Companies',
                      length: companies.length,
                      items: companies,
                      content: <CompaniesList companies={companies} type={type} />,
                  },
              ]
            : []),
        ...(networks?.length > 0
            ? [
                  {
                      title: 'Networks',
                      length: networks.length,
                      items: networks,
                      content: <CompaniesList companies={networks} type="shows" sort="network" />,
                  },
              ]
            : []),
    ];
};
