import React from 'react';

import { TVList } from '../components/list/list';
import { Container, Page, SingleName } from '../components/page/page';
import { query } from '../components/functions';
import { useInfiniteLoading } from '../components/infiniteLoading';
import { Route, Routes, useParams } from 'react-router-dom';

const filterTypes = {
    genre: 'with_genres',
    year: 'primary_release_year',
    keyword: 'with_keywords',
    company: 'with_companies',
    network: 'with_networks',
};

const FilterType = () => {
    let { filterType, filterValue, filterName } = useParams();

    const getItems = ({ page }) => {
        // console.log(page)
        return query(`discover/tv`, `&${filterTypes[filterType]}=${filterValue}&page=${page}`).then(
            (d) => {
                // console.log(d)
                return { items: d.results, totalPages: d.total_pages };
            }
        );
    };

    const { items, hasNext, hasPrevious, loadNext, loadPrevious, loadMoreRef } = useInfiniteLoading(
        {
            getItems,
            options: {
                loadingType: 'partial',
                preload: 'off',
                partialInfiniteLimit: 6,
            },
        }
    );

    const opengraph = {
        title: filterName + ' TV Shows',
        description: 'TV Shows matching ' + filterName,
    };

    return (
        <Page opengraph={opengraph}>
            {hasPrevious && (
                <button type="button" onClick={() => loadPrevious()}>
                    Load Previous
                </button>
            )}
            {items.length > 0 ? (
                <Container>
                    <SingleName style={{ textTransform: 'capitalize' }}>
                        {filterType} : {filterName ? filterName : filterValue}
                    </SingleName>
                    <TVList className="fullsize" title="" list={items} />
                </Container>
            ) : (
                <div>Loading...</div>
            )}
            {hasNext && (
                <div style={{ textAlign: 'center' }}>
                    <button ref={loadMoreRef} type="button" onClick={() => loadNext()}>
                        Load Next
                    </button>
                </div>
            )}
        </Page>
    );
};

export const ShowsPage = () => {
    return (
        <Routes>
            <Route path=":filterType" element={<FilterType />}>
                <Route path=":filterValue" element={<FilterType />}>
                    <Route path=":filterName" element={<FilterType />} />
                </Route>
            </Route>
        </Routes>
    );
};
