import React, { useEffect } from 'react';

import { MovieList } from '../components/list/list';
import { Container, Page, SingleName } from '../components/page/page';
import { query } from '../components/functions';
import { useInfiniteLoading } from '../components/infiniteLoading';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';

const filterTypes = {
    genre: 'with_genres',
    year: 'primary_release_year',
    keyword: 'with_keywords',
    company: 'with_companies',
};

const sortTypes = [
    'popularity.desc',
    'popularity.asc',
    'revenue.desc',
    'revenue.asc',
    'primary_release_date.desc',
    'primary_release_date.asc',
    'vote_average.desc',
    'vote_average.asc',
    'vote_count.desc',
    'vote_count.asc',
];

const FilterType = () => {
    let [searchParams] = useSearchParams();
    let { filterType, filterValue, filterName } = useParams();
    const [results, setResults] = React.useState({});

    const getItems = ({ page }) => {
        return query(
            `discover/movie`,
            `&${filterTypes[filterType]}=${filterValue}&page=${page}&sort_by=${
                sortTypes[searchParams.get('sort') || 0]
            }`
        ).then((d) => {
            setResults(d);
            return { items: d.results, totalPages: d.total_pages };
        });
    };

    const { items, hasNext, hasPrevious, loadNext, loadPrevious, loadMoreRef } = useInfiniteLoading(
        {
            getItems,
            options: {
                loadingType: 'partial',
                preload: 'off',
                partialInfiniteLimit: 6,
            },
            restartTrigger: searchParams.get('sort'),
        }
    );

    useEffect(() => {
        if (searchParams.get('sort') !== undefined) {
        }
    }, [searchParams]);

    // const handleSort = (e) => {
    //     setSearchParams(new URLSearchParams({ sort: e.target.value }).toString());
    // };

    const opengraph = {
        title: filterName + ' Movies',
        description: 'Movies matching ' + filterName,
    };

    return (
        <Page opengraph={opengraph}>
            {/* <select value={searchParams.get('sort')} onChange={handleSort}>
                {sortTypes.map((o, i) => (
                    <option key={i} value={i}>
                        {o}
                    </option>
                ))}
            </select> */}
            {hasPrevious && (
                <button type="button" onClick={() => loadPrevious()}>
                    Load Previous
                </button>
            )}
            {items.length > 0 ? (
                <>
                    <Container>
                        <SingleName>
                            {filterType} : {filterName ? filterName : filterValue}
                        </SingleName>
                        <div>{results.total_results} results</div>
                    </Container>
                    <MovieList className="fullsize" title="" list={items} />
                </>
            ) : (
                <div>Loading...</div>
            )}
            {hasNext && (
                <div style={{ textAlign: 'center' }}>
                    <button ref={loadMoreRef} type="button" onClick={() => loadNext()}>
                        Load Next
                    </button>
                </div>
            )}
        </Page>
    );
};

export const MoviesPage = () => {
    return (
        <Routes>
            <Route path=":filterType" element={<FilterType />}>
                <Route path=":filterValue" element={<FilterType />}>
                    <Route path=":filterName" element={<FilterType />} />
                </Route>
            </Route>
        </Routes>
    );
};
