import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { imageurl } from '../figure/figure';

import cx from 'classnames';
import styles from './page.module.css';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { CommaItem } from '../list/list';

export const Header = ({ title = '', children }) => {
    return (
        <header className={styles.navbar}>
            <Container>
                {title === '' || <h1>{title}</h1>}
                <ul>{children}</ul>
            </Container>
        </header>
    );
};

export const Page = ({
    children,
    className = '',
    style = {},
    backdrop = false,
    opengraph = false,
}) => {
    if (backdrop) {
        style['--backdropUrl'] = `url(${imageurl(backdrop.file_path, 'original')})`;
    }
    return (
        <div className={cx(styles.page, className, { hasBackdrop: backdrop })} style={style}>
            <OpengraphHelmet opengraph={opengraph} />
            {children}
        </div>
    );
};

export const Container = ({ children, className = '', style = {} }) => {
    return (
        <div className={cx(styles.container, className)} style={style}>
            {children}
        </div>
    );
};

export const Stretch = ({ children, className = '', style = {} }) => {
    return (
        <li className={cx(styles.flexStretch, className)} style={style}>
            {children}
        </li>
    );
};
export const NoStretch = ({ children, className = '', style = {} }) => {
    return (
        <li className={cx(styles.flexNone, className)} style={style}>
            {children}
        </li>
    );
};

export const HeaderList = ({ children, className = '', style = {} }) => {
    return (
        <div className={cx(styles.headerList, className)} style={style}>
            {children}
        </div>
    );
};

export const HeaderLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link className={cx({ [styles.active]: match })} to={to} {...props}>
            {children}
        </Link>
    );
};

export const Section = ({ list = [], className = '', children }) => {
    // console.log('section', list);
    return (
        <>{list.length > 0 && <div className={cx(styles.section, className)}>{children}</div>}</>
    );
};
export const SectionHeader = ({ list = [], className = '', title = '', shown = false }) => {
    return (
        <>
            {list.length > 0 && (
                <h2 className={cx(styles.container, className)}>
                    {title} {shown && `(${list.length})`}
                </h2>
            )}
        </>
    );
};

const getLess = (str, length = 50) => {
    if (!str) return;
    const arr = `${str}`.split(' ');
    if (arr.length > length * 1.25) {
        return arr.slice(0, length).join(' ');
    } else return str;
};

const makeParagraphed = (str, paragraph) => {
    if (!str || !paragraph) return str;
    const arr = `${str}`.split(/\n+/);
    return arr.map((o, i) => <p key={i}>{o}</p>);
};

export const SingleProperty = ({
    className = '',
    title = '',
    value,
    more = false,
    paragraph = false,
    collapse = false,
    collapseAtStart = false,
}) => {
    const [showMore, setShowMore] = useState(false);
    const [collapsed, setCollapsed] = useState(collapseAtStart);
    const less = getLess(value, 100);
    return value && value !== '' ? (
        <div className={cx(styles.singleProperty, className)}>
            {collapse ? (
                <button className={styles.title} onClick={() => setCollapsed(!collapsed)}>
                    <strong>{title}</strong>
                    <span>{collapsed ? '▼' : '▲'}</span>
                </button>
            ) : (
                <strong>{title}</strong>
            )}
            {!collapsed && (
                <div className={styles.content}>
                    {!more || `${less}`.length === `${value}`.length ? (
                        makeParagraphed(value, paragraph)
                    ) : !showMore ? (
                        <>
                            {makeParagraphed(less, paragraph)}{' '}
                            <CommaItem onClick={() => setShowMore(true)}>Show More</CommaItem>
                        </>
                    ) : (
                        <>
                            {makeParagraphed(value, paragraph)}{' '}
                            <CommaItem onClick={() => setShowMore(false)}>Show Less</CommaItem>
                        </>
                    )}
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};

export const SingleDescription = ({ children }) => {
    return <div className={styles.singleDescription}>{children}</div>;
};

export const SingleName = ({ href = '', style = {}, children }) => {
    return (
        <div className={styles.singleName} style={style}>
            {href !== '' ? <a href={href}>{children}</a> : <>{children}</>}
        </div>
    );
};

export const ChildProperty = ({ title = '', length = 0, children }) => {
    return length > 0 ? (
        <div className={styles.singleProperty}>
            <strong>{title}</strong>
            {children}
        </div>
    ) : (
        <></>
    );
};

export const OpengraphHelmet = ({
    opengraph = {
        title: 'Filmswith',
        description: 'What was the film with that guy in it?',
        image: '/logo_icon@2x.png',
        url: '/',
    },
}) => {
    return (
        <Helmet>
            {opengraph.title && (
                <title>
                    {opengraph.title !== 'Filmswith'
                        ? `${opengraph.title} - FilmsWith`
                        : opengraph.title}
                </title>
            )}
            {opengraph.description && (
                <meta name={styles.description} content={opengraph.description} />
            )}
            {Object.entries(opengraph).map(([key, value]) => {
                const ogkey = `og:${key}`;

                if (value?.length > 200) {
                    value = value.slice(0, 256) + '...';
                }
                if (key === 'image') {
                    value = imageurl(value);
                }
                return <meta key={key} property={ogkey} content={value} />;
            })}
        </Helmet>
    );
};
