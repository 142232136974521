import React, { useState, useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { queries } from '../../components/functions';
import { doCompare } from '../../components/compare';
import { Container } from '../../components/page/page';
import {
    CommaItem,
    ComparePersonList,
    ComparePersonResultsList,
    CompareRow,
} from '../../components/list/list';
import ReactGA from 'react-ga4';

export const ComparePeople = ({ compare = [] }) => {
    let { compareOption } = useParams();
    let [toCompare, setToCompare] = useState(compare);
    let [comparisons, setComparisons] = useState([]);
    let location = useLocation();

    useEffect(() => {
        let list = compareOption ? compareOption.split(',').map((o) => ({ id: o })) : compare;

        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'People ' + list.map((o) => o.id).join(','),
        });

        queries(
            list.map((o) => ['person/' + o.id, '&append_to_response=movie_credits,tv_credits'])
        ).then((d) => {
            setToCompare(d);
            let compared = doCompare(d, 'people');

            // console.log(compared);
            setComparisons(compared);
        });
    }, [compare, compareOption, location.pathname]);

    return (
        <>
            {toCompare.length > 1 && (
                <Container>
                    <CommaItem href={`/compare/person/${toCompare.map((o) => o.id).join(',')}`}>
                        Share
                    </CommaItem>
                </Container>
            )}
            {toCompare.length > 2 && (
                <CompareRow list={toCompare}>
                    <ComparePersonList list={toCompare} />
                </CompareRow>
            )}
            {comparisons.length > 0 && <ComparePersonResultsList list={comparisons} />}
            {toCompare.length === 0 && (
                <Container>
                    <h2>It looks like there's nothing here</h2>
                    <p>
                        This page is kind of the whole point of this application. As you navigate
                        around this site, you'll see some little circles on some of the pictures.
                        Select some people (or movies coming soon) and this page will fill up. See
                        what actors have worked together before. See what directors like to work
                        with the same people a lot. If you load up on too many, it will be your
                        computer or phone that suffers, so try to keep it at less than six.
                    </p>
                </Container>
            )}
        </>
    );
};
