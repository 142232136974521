import React, { createContext, useContext, useReducer } from 'react';
import { storage } from '../components/storage';

const storeReducer = (state, action) => ({ ...state, ...action });
const storeInitial = {
    settings: storage.get('settings', {}),
    compare: storage.get('compare', []),
};

export const StoreContext = createContext(storeInitial);
export const useStoreContext = () => useContext(StoreContext);

export const StoreContextProvider = ({ children }) => {
    const [store, setStore] = useReducer(storeReducer, storeInitial);

    const handleSetStore = (action) => {
        Object.entries(action).forEach(([k, v]) => {
            storage.set(k, v);
        });
        setStore(action);
    };

    return (
        <StoreContext.Provider value={[store, handleSetStore]}>{children}</StoreContext.Provider>
    );
};
