import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { query, getAge, siftCrew, getYear, uniques } from '../../components/functions';
import {
    FigureGallery,
    MediaImage,
    FigureModal,
    SingleImage,
} from '../../components/figure/figure';
import { PersonOverlay } from '../../components/overlay/overlay';
import { CommaList, MovieList, TVCreditsList } from '../../components/list/list';
import {
    Container,
    Page,
    Section,
    SingleDescription,
    SingleName,
    SingleProperty,
} from '../../components/page/page';
import { useStoreContext } from '../../store/storecontext';

import { IMDBLink } from '../../components/links/links';

const makeYear = (o) => {
    o.release_year = getYear(o.release_date || o.first_air_date);
};

export const PersonProfile = ({ person, setModal }) => {
    //    const [{settings:{adult}}] = useStoreContext();
    const openPhotoModal = () => {
        setModal(person.profile_path);
    };
    return person ? (
        <>
            <SingleImage>
                <MediaImage src={person.profile_path} onClick={openPhotoModal} />
                <PersonOverlay data={person} />
            </SingleImage>
            <SingleDescription>
                <SingleName href={`/person/${person.id}`}>{person.name}</SingleName>

                <CommaList>
                    <IMDBLink id={person?.imdb_id} label="IMDB" />
                </CommaList>

                <SingleProperty title="Age" value={getAge(person)} />
                <SingleProperty title="Birth Date" value={person.birthday} />
                <SingleProperty title="Death" value={person.deathday} />
                <SingleProperty title="Place of Birth" value={person.place_of_birth} />
                <SingleProperty title="Biography" value={person.biography} more paragraph />
            </SingleDescription>
        </>
    ) : (
        <></>
    );
};

export const PersonPage = () => {
    const { personParam } = useParams();

    const [person, setPerson] = useState(false);
    const [modal, setModal] = useState(false);

    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    useEffect(() => {
        setPerson(false);
        query(
            'person/' + personParam,
            '&append_to_response=images,movie_credits,tv_credits&include_image_language=null,en',
            adult
        ).then((d) => {
            d.movie_credits.cast.forEach(makeYear);
            d.movie_credits.crew.forEach(makeYear);
            d.tv_credits.cast.forEach(makeYear);
            d.tv_credits.crew.forEach(makeYear);
            // console.log(d);
            setPerson(d);
        });
    }, [adult, personParam]);

    const opengraph = person
        ? {
              title: person.name,
              description: person.biography,
              image: person.profile_path,
              url: '/person/' + person.id,
          }
        : false;

    return (
        <Page opengraph={opengraph}>
            {person && (
                <>
                    <Container>
                        <PersonProfile person={person} setModal={setModal} />
                    </Container>

                    <FigureGallery
                        images={person.images.profiles}
                        setModal={setModal}
                        adultHandler={(o) => !adult && o.adult}
                    />

                    <Section list={[...person.movie_credits.cast, ...person.movie_credits.crew]}>
                        <MovieList
                            title="Movie Cast"
                            list={person.movie_credits.cast}
                            showamount={true}
                            sortorder="personcast"
                            jobs={uniques(
                                person.movie_credits.cast.reduce((r, o) => [...r, o.character], [])
                            )}
                        />
                        <MovieList
                            title="Movie Crew"
                            list={siftCrew(person.movie_credits.crew)}
                            showamount={true}
                            sortorder="personcrew"
                            jobs={uniques(
                                person.movie_credits.crew.reduce((r, o) => [...r, o.job], [])
                            )}
                        />
                    </Section>

                    <Section list={[...person.tv_credits.cast, ...person.tv_credits.crew]}>
                        <TVCreditsList
                            title="TV Cast"
                            list={person.tv_credits.cast}
                            showamount={true}
                            sortorder="tvcast"
                        />
                        <TVCreditsList
                            title="TV Crew"
                            list={person.tv_credits.crew}
                            showamount={true}
                        />
                    </Section>

                    <FigureModal src={modal} setSrc={setModal} />
                </>
            )}
        </Page>
    );
};
