import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import { slowQueries } from '../components/functions';
import { PersonList, MovieList, TVList } from '../components/list/list';
import { Page } from '../components/page/page';
import { useStoreContext } from '../store/storecontext';
import ReactGA from 'react-ga4';

const initialResults = () => ({
    person: [],
    movie: [],
    tv: [],
});

export const SearchPage = () => {
    const { searchParam } = useParams();
    const [{ settings }] = useStoreContext();
    const [{ person, movie, tv }, setResults] = useReducer(
        (s, m) => ({ ...s, ...m }),
        initialResults()
    );

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/search', title: 'Search ' + searchParam });
        setResults(initialResults());
        slowQueries([
            ['search/person', '&page=1&region=US&query=' + searchParam, settings.adult],
            ['search/movie', '&page=1&region=US&query=' + searchParam, settings.adult],
            ['search/tv', '&page=1&region=US&query=' + searchParam, settings.adult],
        ]).then((d) => {
            setResults({
                person: d[0].results,
                movie: d[1].results,
                tv: d[2].results,
            });
        });
    }, [searchParam, settings.adult]);

    const opengraph = {
        title: 'Search = ' + searchParam,
        description: 'Search results for ' + searchParam,
    };

    return (
        <Page opengraph={opengraph}>
            {person.length > 0 && <PersonList list={person} title="Person" />}
            {movie.length > 0 && <MovieList list={movie} title="Movie" />}
            {tv.length > 0 && <TVList list={tv} title="TV" />}
        </Page>
    );
};
