import React, { useState, useEffect } from 'react';

import { PersonOverlay, MovieOverlay, TVOverlay } from '../overlay/overlay';
import { getYear } from '../functions';
import { LazyImageLoader } from '../lazyimage/lazyimage';
import { useStoreContext } from '../../store/storecontext';
import { Modal } from '../modal/modal';
import { Badge } from '../badge/badge';
import cx from 'classnames';
import styles from './figure.module.css';

export const imageurl = (src, quality = 200) => {
    const q = quality > 0 ? 'w' + quality : 'original';
    return `https://image.tmdb.org/t/p/${q}${src}`;
};

export const LazyMediaImage = ({ quality = 200, src = '', alt = '', adult }) => {
    return (
        <LazyImageLoader offset={1000}>
            {src && (
                <img
                    className={cx(styles.mediaImage, { [styles.adultImage]: adult })}
                    alt={alt}
                    src={imageurl(src, quality)}
                />
            )}
        </LazyImageLoader>
    );
};

export const MediaImage = ({
    className = '',
    quality = 200,
    src = '',
    alt = '',
    adult,
    onClick = () => {},
}) => {
    return src ? (
        <img
            className={cx(styles.mediaImage, className, { [styles.adultImage]: adult })}
            alt={alt}
            src={imageurl(src, quality)}
            onClick={onClick}
        />
    ) : (
        <></>
    );
};

const ImageFigure = ({
    image = '',
    Overlay = null,
    lazyload = true,
    badge = '',
    adult,
    children,
}) => {
    return (
        <>
            <div className={styles.itemImage}>
                {lazyload ? (
                    <LazyMediaImage src={image} adult={adult} />
                ) : (
                    <MediaImage src={image} adult={adult} />
                )}
                {Overlay}
                {badge !== '' && <Badge value={badge} />}
            </div>
            <div className={styles.itemDescription}>{children}</div>
        </>
    );
};

export const Figure = ({ className = '', children }) => {
    return <div className={cx(styles.figure, className)}>{children}</div>;
};

export const FigureModal = ({ src, setSrc }) => {
    const handleClick = (e) => {
        setSrc(false);
    };
    return (
        <Modal show={src !== false} hasBack={false}>
            <button className={cx(styles.modalFullimage, styles.canClick)} onClick={handleClick}>
                <img className={styles.noClick} src={imageurl(src, -1)} alt="" />
            </button>
        </Modal>
    );
};

export const VideoModal = ({ src, setSrc }) => {
    const handleClick = (e) => {
        setSrc(false);
    };
    return (
        <Modal show={src !== false} handleClose={handleClick}>
            <iframe
                src={`https://www.youtube.com/embed/${src.key}`}
                allowFullScreen
                title="Embedded youtube"
            />
        </Modal>
    );
};

export const CompareFigure = ({ data }) => {
    const Overlay =
        data.type === 'person' ? PersonOverlay : data.type === 'tv' ? TVOverlay : MovieOverlay;
    return (
        <ImageFigure
            image={data.profile_path || data.poster_path || data.img}
            lazyload={false}
            Overlay={<Overlay data={data} position="top right" />}
        >
            <div className={styles.itemTitle} title={data.name || data.title}>
                <strong className={styles.marquee}>{data.name || data.title}</strong>
            </div>
        </ImageFigure>
    );
};

export const PersonFigure = ({ data }) => {
    let [roles, setRoles] = useState([]);
    let [jobs, setJobs] = useState([]);
    useEffect(() => {
        if (data.roles) {
            setRoles(data.roles.map((o) => o.character));
        }
        if (data.jobs) {
            setJobs(data.jobs);
        }
    }, [data]);
    return (
        <ImageFigure
            lazyload={true}
            image={data.profile_path || data.img}
            Overlay={<PersonOverlay data={data} />}
        >
            <div className={styles.itemName} title={data.name}>
                <strong className={styles.marquee}>{data.name}</strong>
            </div>
            {data.character && (
                <div className={styles.itemJobs} title={data.character}>
                    {data.character}
                </div>
            )}
            {roles.length > 0 && (
                <div className={styles.itemJobs} title={roles.join(', ')}>
                    {roles.map((o, i) => (
                        <div key={i + '_' + o?.id} className={styles.itemJob}>
                            {o}
                        </div>
                    ))}
                </div>
            )}
            {jobs.length > 0 && (
                <div className={styles.itemJobs} title={jobs.join(', ')}>
                    {jobs.map((o, i) => (
                        <div key={i + '_' + o?.id} className={styles.itemJob}>
                            {o}
                        </div>
                    ))}
                </div>
            )}
        </ImageFigure>
    );
};

export const VideoFigure = ({ data }) => {
    return (
        <ImageFigure image={data.poster_path || data.img}>
            <div className={styles.itemTitle} title={data.name}>
                {data.name}
            </div>
            <div className={styles.itemYear}>{data.release_date}</div>
        </ImageFigure>
    );
};

export const MovieFigure = ({ data }) => {
    // console.log(data)
    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    return (
        <ImageFigure
            image={data.poster_path || data.img}
            adult={!adult && data.adult}
            lazyload={true}
            Overlay={<MovieOverlay data={data} />}
        >
            <div className={styles.itemTitle} title={data.title}>
                <span className={styles.marquee}>{data.title}</span>
            </div>
            {data.release_year && <div className={styles.itemYear}>{data.release_year}</div>}
            {data.release_date && !data.release_year && (
                <div className={styles.itemYear}>{data.release_date}</div>
            )}
            {data.character && (
                <div className={styles.itemJobs} title={data.character}>
                    {data.character}
                </div>
            )}
            {data.jobs && (
                <div className={styles.itemJobs} title={data.jobs.join(', ')}>
                    {(data.jobs || []).map((o, i) => (
                        <div key={i} className={styles.itemJob}>
                            {o}
                        </div>
                    ))}
                </div>
            )}
        </ImageFigure>
    );
};

export const TVFigure = ({ data }) => {
    return (
        <ImageFigure
            lazyload={true}
            image={data.poster_path || data.img}
            Overlay={<TVOverlay data={data} />}
        >
            <div className={styles.itemTitle} title={data.name}>
                {data.name}
            </div>
            <div className={styles.itemYear}>{data.first_air_date}</div>
            {data.character && data.character !== '' && (
                <div className={styles.itemJobs}>{data.character}</div>
            )}
            {data.job && data.job !== '' && <div className={styles.itemJobs}>{data.job}</div>}
        </ImageFigure>
    );
};

export const TVCreditsFigure = ({ data }) => {
    return (
        <ImageFigure
            image={data.poster_path || data.img}
            Overlay={<TVOverlay data={data} />}
            badge={data.episode_count}
            lazyload={true}
        >
            <div className={styles.itemTitle} title={data.name}>
                {data.name}
            </div>
            <div className={styles.itemYear}>{data.first_air_date}</div>
            {data.character && data.character !== '' && (
                <div className={styles.itemJobs}>{data.character}</div>
            )}
            {data.job && data.job !== '' && <div className={styles.itemJobs}>{data.job}</div>}
        </ImageFigure>
    );
};

export const SeasonFigure = ({ data }) => {
    return (
        <ImageFigure lazyload={true} image={data.poster_path} badge={data.episode_count}>
            <div className={styles.itemTitle}>{data.name}</div>
            <div className={styles.itemYear}>{getYear(data.air_date, '')}</div>
        </ImageFigure>
    );
};

export const EpisodeFigure = ({ data }) => {
    return (
        <ImageFigure
            lazyload={true}
            image={data.still_path}
            badge={data.runtime && `${data.runtime}m`}
        >
            <div className={styles.itemTitle} title={data.name}>
                {data.episode_number}. {data.name}
            </div>
            <div className={styles.itemYear}>{data.air_date}</div>
        </ImageFigure>
    );
};

export const FigureGallery = ({ images, setModal, adultHandler, className = '' }) => {
    return (
        <ul className={cx(styles[className], styles.container, styles.figureGallery)}>
            {images.length > 0 &&
                images.map((o, i) => (
                    <li key={i}>
                        <MediaImage
                            src={o.file_path}
                            adult={adultHandler(o)}
                            alt=""
                            onClick={() => {
                                setModal(o.file_path);
                            }}
                        />
                    </li>
                ))}
        </ul>
    );
};

export const SingleImage = ({ children }) => {
    return <div className={cx(styles.singleImage, styles.canClick)}>{children}</div>;
};
