import React, { useReducer, useEffect } from 'react';

import { Page, Section } from '../components/page/page';
import { MovieList, TVList } from '../components/list/list';
import { queries, searchParams } from '../components/functions';

const emptylist = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

export const RecentPage = () => {
    let [items, setItems] = useReducer((s, m) => ({ ...s, ...m }), {
        inTheatres: emptylist,
        comingSoon: emptylist,
        popularShows: emptylist,
        airingToday: emptylist,
    });

    useEffect(() => {
        const today = new Date();
        const previously = new Date();
        previously.setDate(previously.getDate() - 30);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        queries([
            // ["movie/now_playing",searchParams({
            //    "page":"1",
            // })],
            // ["movie/upcoming",searchParams({
            //    "page":"1",
            // })],
            [
                'discover/movie',
                searchParams({
                    page: '1',
                    // "watch_region": "US",
                    // "with_watch_monetization_types":"flatrate|free|ads|rent|buy",
                    sort_by: 'popularity.desc',
                    'primary_release_date.lte': today.toJSON().slice(0, 10),
                }),
            ],
            [
                'discover/movie',
                searchParams({
                    page: '1',
                    // "watch_region": "US",
                    // "with_watch_monetization_types":"flatrate|free|ads|rent|buy",
                    sort_by: 'popularity.desc',
                    'primary_release_date.gte': tomorrow.toJSON().slice(0, 10),
                }),
            ],
            [
                'discover/tv',
                searchParams({
                    page: '1',
                    watch_region: 'US',
                    with_watch_monetization_types: 'flatrate|free|ads|rent|buy',
                    sort_by: 'popularity.desc',
                    'air_date.lte': today.toJSON().slice(0, 10),
                    'air_date.gte': previously.toJSON().slice(0, 10),
                }),
            ],
            [
                'discover/tv',
                searchParams({
                    page: '1',
                    watch_region: 'US',
                    with_watch_monetization_types: 'flatrate|free|ads|rent|buy',
                    sort_by: 'popularity.desc',
                    'air_date.lte': today.toJSON().slice(0, 10),
                    'air_date.gte': today.toJSON().slice(0, 10),
                }),
            ],
        ]).then((d) => {
            // console.log("Returned",d)
            setItems({
                inTheatres: d[0].results,
                comingSoon: d[1].results,
                popularShows: d[2].results,
                airingToday: d[3].results,
            });
        });
    }, []);

    return (
        <Page>
            <Section list={[0]}>
                <MovieList
                    title="In Theatres"
                    list={items.inTheatres}
                    sortorder="releasedateDESC"
                />
                <MovieList title="Coming Soon" list={items.comingSoon} sortorder="releasedateASC" />
            </Section>
            <Section list={[0]}>
                <TVList title="Popular Shows" list={items.popularShows} />
                <TVList title="Airing Today" list={items.airingToday} />
            </Section>
        </Page>
    );
};
