import React, { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';
import { query, siftCrew, getYear, uniques, timeBreakdown } from '../../components/functions';
import { MediaImage, FigureModal, SingleImage, VideoModal } from '../../components/figure/figure';
import {
    CommaList,
    MovieList,
    PersonList,
    SpacedList,
    TabbedList,
} from '../../components/list/list';
import {
    Page,
    Section,
    SingleProperty,
    ChildProperty,
    Container,
    SingleDescription,
    SingleName,
} from '../../components/page/page';
import { MovieOverlay } from '../../components/overlay/overlay';
import { AmazonLink, IMDBLink, ShareLink } from '../../components/links/links';
import { useStoreContext } from '../../store/storecontext';
import { makeMediaDataSet } from '../../components/mediaset/MediaDataSet';

const Genres = ({ genres }) => {
    return (
        <CommaList>
            {genres.map((o, i) => (
                <Link key={i} to={`/movies/genre/${o.id}/${o.name}`}>
                    {o.name}
                </Link>
            ))}
        </CommaList>
    );
};

const Keywords = ({ keywords }) => {
    return (
        <CommaList>
            {keywords.map((o, i) => (
                <Link key={i} to={`/movies/keyword/${o.id}/${o.name}`}>
                    {o.name}
                </Link>
            ))}
        </CommaList>
    );
};

const getReleases = (releases) => {
    const ratings = (Array.isArray(releases) ? releases : []).reduce((r, o) => {
        if (o.certification !== '') r.push(o.certification);
        return r;
    }, []);
    return ratings;
};
const getRating = (movie) => {
    const releases = movie?.release_dates?.results ?? {};

    const ratings = [
        ...new Set(
            releases
                .map((d) => {
                    const r = getReleases(d.release_dates);
                    return r;
                })
                .flat()
        ),
    ];

    const us_release = releases.find((o) => o.iso_3166_1 === 'US') ?? {};
    const other_productions = movie.production_countries.filter((o) => o.iso_3166_1 !== 'US');
    const production_releases = other_productions
        .map((p) => {
            const r = releases.find((o) => o.iso_3166_1 === p.iso_3166_1);
            const ratings = getReleases(r?.release_dates);
            return ratings;
        })
        .flat();

    const rating = getReleases(us_release.release_dates)[0] ?? production_releases[0] ?? null;
    //console.log(rating, ratings, releases);

    return { rating, ratings };
};

export const MovieProfile = ({ movie, setModal }) => {
    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();
    const ratings = getRating(movie);
    const release_year = getYear(movie.release_date, movie.status);

    // console.log({ movie });
    return movie ? (
        <Container>
            <SingleImage>
                <MediaImage
                    src={movie.poster_path}
                    onClick={() => {
                        setModal(movie.poster_path);
                    }}
                    adult={!adult && movie.adult}
                />
                <MovieOverlay data={movie} />
            </SingleImage>
            <SingleDescription>
                <SingleName>
                    <span>{movie.title}</span>{' '}
                    <small>
                        <ShareLink
                            url={`/movie/${movie.id}/${movie.title
                                .toLowerCase()
                                .replace(/[^a-z0-9]/gi, '-')}`}
                            title={movie.title}
                        />
                    </small>
                </SingleName>

                <SpacedList>
                    <CommaList>
                        <a href={`/movies/year/${release_year}`} title={movie.release_date}>
                            {release_year}
                        </a>
                        {movie.runtime !== 0 && (
                            <span title={movie.runtime}>{timeBreakdown(movie.runtime)}</span>
                        )}
                        {ratings.rating && (
                            <span title={ratings.ratings.join(', ')}>{ratings.rating}</span>
                        )}
                    </CommaList>
                    <CommaList>
                        <IMDBLink id={movie.imdb_id} label="IMDb" />
                        <AmazonLink
                            search={`${movie.title} ${getYear(movie.release_date)}`}
                            label="Amazon"
                        />
                    </CommaList>
                </SpacedList>

                <ChildProperty title="Genres" length={movie.genres.length}>
                    <Genres genres={movie.genres} />
                </ChildProperty>
                <ChildProperty title="Keywords" length={movie.keywords.keywords.length}>
                    <Keywords keywords={movie.keywords.keywords} />
                </ChildProperty>
                <SingleProperty title="Overview" value={movie.overview} more paragraph />
            </SingleDescription>
        </Container>
    ) : (
        <></>
    );
};

export const MoviePage = () => {
    let { movieParam } = useParams();
    let [movie, setMovie] = useState(false);
    let [modal, setModal] = useState(false);
    let [videoModal, setVideoModal] = useState(false);
    let [tabbedContent, setTabbedContent] = useState([]);

    const [
        {
            settings: { adult },
        },
    ] = useStoreContext();

    useEffect(() => {
        setMovie(false);
        query(
            `movie/${movieParam}`,
            `&append_to_response=credits,videos,images,recommendations,similar,keywords,release_dates&include_image_language=null,en`
        ).then(async (moviedata) => {
            setMovie(moviedata);

            const collection = moviedata.belongs_to_collection
                ? await query(`collection/${moviedata.belongs_to_collection.id}`)
                : false;

            const tabset = {
                videos: moviedata?.videos?.results,
                posters: moviedata?.images?.posters,
                backdrops: moviedata?.images?.backdrops,
                logos: moviedata?.images?.logos,
                companies: moviedata?.production_companies,
            };

            if (collection) {
                tabset.collection = collection.parts;
            }

            const madeData = makeMediaDataSet({
                media: tabset,
                id: moviedata.id,
                type: 'movies',
                setModal,
                setVideoModal,
                adult,
            });
            console.log({ collection, moviedata, tabset, madeData });
            setTabbedContent(madeData);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [movieParam]);

    const opengraph = movie
        ? {
              title: `${movie.title} (${getYear(movie.release_date, movie.status)})`,
              description: movie.overview,
              image: movie.poster_path,
              url: `/movie/${movie.id}/${movie.title.toLowerCase().replace(/[^a-z0-9]/gi, '-')}`,
          }
        : false;

    return movie ? (
        <Page backdrop={movie?.images?.backdrops[0]} opengraph={opengraph}>
            <MovieProfile movie={movie} setModal={setModal} />

            <TabbedList list={tabbedContent} />

            <Section className="section-credits" list={[0]}>
                <PersonList
                    title="Cast"
                    list={movie.credits.cast}
                    showamount
                    sortorder="moviecast"
                />
                <PersonList
                    title="Crew"
                    list={siftCrew(movie.credits.crew)}
                    showamount
                    sortorder="moviecrew"
                    defaultSort="jobASC"
                    jobs={(() => {
                        return uniques(movie.credits.crew.reduce((r, o) => [...r, o.job], []));
                    })()}
                />
            </Section>

            <Section className="sectionRecommendations" list={[0]}>
                <MovieList title="Similar" list={movie.similar.results} />
                <MovieList title="Recommendations" list={movie.recommendations.results} />
            </Section>
            <FigureModal src={modal} setSrc={setModal} />
            <VideoModal src={videoModal} setSrc={setVideoModal} />
        </Page>
    ) : (
        <></>
    );
};
