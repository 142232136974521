import React from 'react';
import imdb from '../../img/imdb.svg';
import amazon from '../../img/amazon.svg';
import share from '../../img/share.svg';

import styles from './links.module.css';

export const Icon = ({ src, alt = 'icon' }) => {
    return <img src={src} alt={alt} className={styles.svgIcon} />;
};

export const AmazonLink = ({ search, label }) => {
    const urisearch = encodeURIComponent(search);
    return search && search !== '' ? (
        <a
            href={`https://www.amazon.com/s/?url=search-alias%3Dmovies-tv&field-keywords=${urisearch}&tag=hamiltondraws-20`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {label ?? <Icon src={amazon} />}
        </a>
    ) : (
        <></>
    );
};
export const IMDBLink = ({ id, label }) => {
    return id && id !== '' ? (
        <a href={`https://imdb.com/title/${id}`} target="_blank" rel="noopener noreferrer">
            {label ?? <Icon src={imdb} />}
        </a>
    ) : (
        <></>
    );
};

export const ShareLink = ({ url, label, text = '', title = '' }) => {
    const shareData = {
        title: title,
        // text: text,
        url: url,
    };
    const handleClick = async () => {
        try {
            await navigator.share(shareData);
        } catch (err) {}
    };
    return url && url !== '' ? (
        <button className={styles.btn} type="button" onClick={handleClick}>
            {label ?? <Icon src={share} />}
        </button>
    ) : (
        <></>
    );
};
